/**
 * @name: waline 评论系统
 * @date: 2022-06-05 16:52:38
 * @authors: luojun1@thinkive.com
 * @path: src\components\waline.js
 */

import * as React from "react"
import {useEffect} from "react"
import { init } from "@waline/client"
import "@waline/client/dist/waline.css"
import "../style.waline.css"

const Waline = () => {
  useEffect(() => {
    init({
      el: '#waline-mount',
      serverURL: 'https://hwaline.vercel.app/'
    })
  }, [])

  return (
    <div id="waline-mount" />
  )
}

export default Waline
