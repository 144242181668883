 /**
  * @name: 版权声明
  * @date: 2022-06-04 23:19:10
  * @authors: luojun1@thinkive.com
  * @path: src\components\copyrightNotice.js
  */
import * as React from "react"
 import {useEffect, useState} from "react"

 const CopyrightNotice = () => {
  const [href, setHref] = useState('获取中...')

  useEffect(() => {
    setHref(window.location.href)
  }, [])

  return (
    <blockquote className="copyright">
      <div>
        作者: <a href="/">hasayake.luo</a>
      </div>
      <div>
        本文地址: <a href={href}>{href}</a>
      </div>
      <div>
        版权声明: 署名-非商业性使用-禁止演绎 4.0 国际
        {` `}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://creativecommons.org/licenses/by-nc-nd/4.0/deed.zh">
          &nbsp;(CC BY-NC-ND 4.0)
        </a>
      </div>
    </blockquote>
  )
 }

 export default CopyrightNotice
